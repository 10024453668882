<template>
    <template v-if="href">
        <a :href="href">
            <NuxtImg class="aspect-auto" v-bind="props" />
        </a>
    </template>
    <template v-else-if="isTitle">
        <h1>
            <NuxtImg class="aspect-auto" v-bind="props" sizes="xl:1280 lg:1024 md:672px sm:576px xs:256px" />
        </h1>
    </template>
    <template v-else-if="zoomable">
        <ZoomImage v-bind="props" sizes="xl:1280 lg:1024 md:672px sm:576px xs:256px" />
    </template>
    <template v-else>
        <NuxtImg class="aspect-auto" v-bind="props" sizes="xl:1280 lg:1024 md:672px sm:576px xs:256px" />
    </template>
</template>
<!-- TODO: Add quality=90 when https://github.com/nuxt/image/pull/673 -->
<script setup>
const props = defineProps({
    src: String,
    alt: String,
    height: String,
    width: String,
    href: String,
    quality: String,
    loading: String,
    class: String,
    title: String,
    isTitle: Boolean,
    zoomable: Boolean,
    provider: String
})
</script>
